import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { EyeOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { listPromotions } from "../function/promotion";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Product.css';

const { Meta } = Card;

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [isHovered, setIsHovered] = useState(false);
  const [hasPromotion, setHasPromotion] = useState(false);

  const { _id, title, description, images, price, sold, } = product;

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const res = await listPromotions();
        const promotions = res.data;
        const productPromotion = promotions.find(promotion =>
          promotion.products.some(p => p._id === product._id)
        );

        if (productPromotion) {
          setHasPromotion(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPromotions();
  }, [product]);

  const handleAddToCart = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up to the Card
    let cart = [];
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }
    cart.push({
      ...product,
      count: 1
    });

    let unique = _.uniqWith(cart, _.isEqual);
    localStorage.setItem('cart', JSON.stringify(unique));
    dispatch({
      type: 'ADD_TO_CART',
      payload: unique
    });
    dispatch({
      type: 'SET_VISIBLE',
      payload: true
    });
  };

  // const shortenedDescription = description.slice(0, 15);
  const cleanedDescription = description.replace(/<[^>]*>/g, '');  // ใช้ RegEx ลบ HTML tag
  const shortenedDescription = cleanedDescription.slice(0, 24) + "..."; // ตัดข้อความที่เหลือให้พอดี


  // Handle navigation to SingleProductCard
  const handleCardClick = () => {
    navigate(`/product/${_id}`); // Navigate to SingleProductCard with product ID
  };

  // Format the price for Thai currency
  const formattedPrice = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(price);


  return (
    <div className='shop-card-container'>
      {hasPromotion && (
        <div className='promotion-label'>Promotion</div>
      )}
      <Card
        hoverable
        className='shop-card mx-auto'
        cover={
          <img
            className='p-1 product-image'
            alt='example'
            src={images && images.length ? images[0].url : ''}
          />
        }
        // actions={[
        //   <EyeOutlined className='text-warning action-icon' onClick={handleCardClick} />, 
        //   <ShoppingCartOutlined onClick={handleAddToCart} cclassName='text-danger action-icon' /> 
        // ]}
        style={{
          boxShadow: isHovered ? '0 4px 8px rgba(150, 27, 78, 0.7)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease-in-out'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleCardClick}
      >
        <Meta title={title} description={shortenedDescription} />
        <div className="shop-price">
          <h4>{formattedPrice}</h4>
          {sold > 0 && <p>ขาย {sold}</p>}
        </div>
      </Card>
    </div>
  );
};

export default ProductCard;

