import React from "react";
import NewProduct from "../home/NewProduct";
import BestSeller from "../home/BestSeller";
import { Carousel, Row, Col, Button } from "react-bootstrap";
import ReactTypingEffect from "react-typing-effect";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./css/Home.css";
import { Link } from "react-router-dom"; // นำเข้า Link จาก react-router-dom
import MenubarUserHomeNew from "../layouts/MenubarUserHomeNew";
// import MenubarUserHomeNew from "../page/MenuUserHGome/MenubarUserHomeNew";

import DonatePok from "./imageDonate/1.jpg";

import firstSlideImage from "./images/1.png";
import secondSlideImage from "./images/2.png";
import thirdSlideImage from "./images/3.png";
import fourthSlideImage from "./images/4.png";
import fifthSlideImage from "./images/5.png";
import MUVITA1 from "./images/MUVITA1.png";
import MUVITA2 from "./images/MUVITA2.png";
import PANAPRO from "./images/Panapro.png";
import UNIQUE from "./images/unique.jpg";
import UNIQUE1 from "./images/unique1.png";
import YEC from "./images/YEC.jpg";
import DreamLabImg from "./images/DreamLab.jpg";
import LeDreamImg from "./images/LeDream.jpg";
import by from "./images/45221.514.png";

import lineImage from "./image/line.png";
import logo from "./auth/logo/logo.png"; // ใส่ path ของโลโก้ที่นี่
import Footer from "../layouts/Footer";

import u1SlideImage from "./imageUnique/1.jpg";
import u2SlideImage from "./imageUnique/2.jpg";
import u3SlideImage from "./imageUnique/3.jpg";
import u4SlideImage from "./imageUnique/4.jpg";
import u5SlideImage from "./imageUnique/5.jpg";

import y1SlideImage from "./imageYooEnCare/1.jpg";
import y2SlideImage from "./imageYooEnCare/2.jpg";
import y3SlideImage from "./imageYooEnCare/3.jpg";
import y4SlideImage from "./imageYooEnCare/4.jpg";
import y5SlideImage from "./imageYooEnCare/5.jpg";

import "../page/Home.css";
import HotUniqare from "../home/HotUniqare";
import CategoryProduct from "../home/CategoryProduct";
import YooEnCare from "../home/YooEnCare";
import LeDream from "../home/LeDream";
import MenubarUserHomeProduct from "../layouts/MenubarUserHomeProduct";
import MenubarUserHome from "../layouts/MenubarUserHome";
import AllProduct from "../home/AllProduct";

const Home = () => {
  const navigate = useNavigate(); // ใช้ useNavigate เพื่อการนำทาง

  const handleButtonClick = () => {
    navigate("/contact-shop"); // นำทางไปยัง component1 เมื่อคลิกปุ่ม
  };

  const handleNavigateToShop = (category, categoryType) => {
    navigate(`/shop-origi?category=${category}&categoryType=${categoryType}`);
  };

  return (
    <>

      {/* <div className="row d-flex justify-content-center mt-0">
        <div className="col-md-3 text-center mt-5">
          <Link to="/partner">
            <Button className="blinking-button" variant="danger" style={{ borderRadius: '30px' }}>
              Partner คลิกที่นี่
            </Button>
          </Link>
        </div>
      </div> */}
      {/* Carousel Container */}


      <div className="row  mt-0 ">
        <div className="col-md-12">
          <Carousel interval={2000}>
            <Carousel.Item>
              <img className="d-block w-100" src={PANAPRO} alt="Panapro" />
            </Carousel.Item>
            {/* <Carousel.Item>
              <img className="d-block w-100" src={MUVITA1} alt="Muvita 1" />
            </Carousel.Item> */}

            <Carousel.Item>
              <img className="d-block w-100" src={MUVITA2} alt="Muvita 2" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={UNIQUE} alt="unique 1" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={UNIQUE1} alt="unique 1" />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      <div className="container mt-5 d-flex justify-content-center mt-0 ">

        <div className="col-md-12 mb-3 ">
          <MenubarUserHomeNew />
        </div>
      </div>
      {/* <div className="container mt-5 ">
        <div className="menu col-md-12 mb-3">
          <div className="row mt-0">
            <div
              className="d-flex flex-column align-items-left justify-content-center"
              style={{ width: "100%" }}
            >

              <p>PRODUCT SERVICE</p>
              
            </div>
          </div>{" "}
          
          <MenubarUserHomeProduct />
        </div>
      </div> */}

      {/* <div className="box-shadow">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 Card-Product">
            360 Hot Deals
          </div>
        </div>
        <BestSeller />
      </div> */}

      <div className="box-shadow mt-5">
        <div className="col-12 custom-headingHotProduct-container">
          <div className="custom-heading blinking-boxss">
            {/* 360 Hot Deals */}
          </div>
        </div>
        <BestSeller />
      </div>

      {/* <div className="box-shadow mt-5">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 Card-Product">
            สินค้ามาใหม่ประจำเดือน
          </div>
        </div>
        <NewProduct />
      </div> */}

      <div className="box-shadow mt-5 mb-1">
        <div className="col-12 custom-headingNewProduct-container mb-1">
          <div className="custom-heading blinking-boxss">
            {/* สินค้ามาใหม่ประจำเดือน */}
          </div>
        </div>
        <NewProduct />
      </div>
      {/* <div className=" carousel-container">
        <div className="row container mt-5 d-flex">
          <div className="menu col-md-4 mb-3">
            {" "}
 
            <MenubarUserHomeNew />
          </div>
          <div className="col-md-8 flex-grow-1">
            {" "}
            
            <div className="row">
              <Carousel interval={2000}>
                <Carousel.Item>
                  <img className="d-block w-100" src={PANAPRO} alt="Panapro" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={MUVITA1} alt="Muvita 1" />
                </Carousel.Item>

                <Carousel.Item>
                  <img className="d-block w-100" src={MUVITA2} alt="Muvita 2" />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container mt-5">
        <Carousel interval={3000}>
          <Carousel.Item>
            <Row>
              <Col>
                <img
                  className="d-block w-100"
                  src={u1SlideImage}
                  alt="First slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={u2SlideImage}
                  alt="Second slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={u3SlideImage}
                  alt="Third slide"
                />
              </Col>
            </Row>
            <Carousel.Caption>
              <h3>First group of slides</h3>
              <p>Some description for the first group.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col>
                <img
                  className="d-block w-100"
                  src={fourthSlideImage}
                  alt="Fourth slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={u4SlideImage}
                  alt="Fifth slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={u5SlideImage}
                  alt="Sixth slide"
                />
              </Col>
            </Row>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div> */}
      {/* <div className="container mt-5">
        <Carousel interval={3000}>
          <Carousel.Item>
            <Row>
              <Col>
                <img
                  className="d-block w-100"
                  src={y1SlideImage}
                  alt="First slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={y2SlideImage}
                  alt="Second slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={y3SlideImage}
                  alt="Third slide"
                />
              </Col>
            </Row>
            
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col>
                <img
                  className="d-block w-100"
                  src={y4SlideImage}
                  alt="Fourth slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={y4SlideImage}
                  alt="Fifth slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={y5SlideImage}
                  alt="Sixth slide"
                />
              </Col>
            </Row>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div> */}

      {/* <div className="box-shadow mt-5">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 custom-heading blinking-box">
            Unique Care
          </div>
        </div>
        
        <HotUniqare />
      </div>
      <div className="box-shadow mt-5">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 custom-heading blinking-box">
            Yoo en care
          </div>
        </div>
        
        <YooEnCare />
      </div>
      <div className="box-shadow mt-5">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 custom-heading blinking-box">
            LeDream
          </div>
        </div>
        
        <LeDream />
      </div>
      <div className="box-shadow mt-5">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 custom-heading blinking-box">
            Good Health Good Job
          </div>
        </div>
        
        <HotUniqare />
      </div>
      <div className="box-shadow mt-5">
        <div className="center-container">
          <div className="text-center p-3 mt-1 mb-1 custom-heading blinking-box">
            Care U Home
          </div>
        </div>
        
        <HotUniqare />
      </div> */}

      <div className="container d-flex flex-column justify-content-center align-items-center"></div>
      <div className="row  d-flex justify-content-center align-items-center">
        <div
          className="col-10 col-md-11 mx-auto d-flex justify-content-center align-items-center"
          onClick={() => handleNavigateToShop("supplement-for-health", "360")}
        >
          <img
            className="d-block w-100"
            src={PANAPRO}
            alt="Panapro"
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          />
        </div>

        <div
          className="col-10 col-md-11 mx-auto d-flex justify-content-center align-items-center"
          onClick={() => handleNavigateToShop("supplement-for-health", "360")}
        >
          <img
            className="d-block w-100 mt-3"
            src={MUVITA2}
            alt="Muvita 2"
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          />
        </div>

        <div
          className="col-10 col-md-11 mx-auto d-flex justify-content-center align-items-center mt-3"
        >
          <img
            className="d-block w-100"
            src={UNIQUE}
            alt="Unique"
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          />
        </div>

        <div
          className="col-10 col-md-11 mx-auto d-flex justify-content-center align-items-center"
          onClick={() => handleNavigateToShop("yoo-en-care", "service")}
        >
          <img
            className="d-block w-100 mt-3"
            src={YEC}
            alt="YEC"
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          />
        </div>

        <div
          className="col-10 col-md-11 mx-auto d-flex justify-content-center align-items-center"
          onClick={() => handleNavigateToShop("dreamlab", "service")}
        >
          <img
            className="d-block w-100 mt-3"
            src={DreamLabImg}
            alt="Dreamlab"
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            }}
          />
        </div>
      </div>

      <div>
      <div className="row mt-3 d-flex justify-content-center align-items-center">
        <div className="col-6 col-md-5">
          <div className="custom-box">
            <p className="custom-text">https://www.360healthyshop.com/</p>
            <p className="custom-text">โฆษณา</p>
            <p className="custom-text">ขนาดภาพ 600x400 Pixel</p>
            <p className="custom-text">โทร: 084-1652479</p>
          </div>
        </div>
        <div className="col-6 col-md-5">
          <div className="custom-box">
            {/* <p className="custom-text">https://www.360healthyshop.com/</p>
            <p className="custom-text">โฆษณา</p>
            <p className="custom-text">ขนาดภาพ 600x400 Pixel</p>
            <p className="custom-text">โทร: 084-1652479</p> */}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-4 col-md-4">
          <div className="custom-box">
            <p className="custom-text">https://www.360healthyshop.com/</p>
            <p className="custom-text">โฆษณา</p>
            <p className="custom-text">ขนาดภาพ 400x200 Pixel</p>
            <p className="custom-text">โทร: 084-1652479</p>
          </div>
        </div>
        <div className="col-4 col-md-4">
          <div className="custom-box">
            {/* <p className="custom-text">https://www.360healthyshop.com/</p>
            <p className="custom-text">โฆษณา</p>
            <p className="custom-text">ขนาดภาพ 400x200 Pixel</p>
            <p className="custom-text">โทร: 084-1652479</p> */}
          </div>
        </div>
        <div className="col-4 col-md-4">
          <div className="custom-box">
            {/* <p className="custom-text">https://www.360healthyshop.com/</p>
            <p className="custom-text">โฆษณา</p>
            <p className="custom-text">ขนาดภาพ 600x400 Pixel</p>
            <p className="custom-text">โทร: 084-1652479</p> */}
          </div>
        </div>
      </div>

      </div>
      
      <div className="box-shadow mt-1">
        <Link to="/shop">
          <div className="col-12 custom-headingAllProduct-container ">
            <div className="custom-heading blinking-boxss mt-1">
              {/* สินค้าทั้งหมด */}
            </div>
          </div>
        </Link>

        <AllProduct />
      </div>

      {/* YouTube video */}
      <div className="row mt-5">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center mx-auto" style={{ marginRight: '50px' }}>
          <div className="ratio ratio-16x9">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/2F24ztMGPjE?si=jXAg5RehB5NXykEc"
              title="YouTube video player"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="text-center mt-5">
          <button className="btn me-3" onClick={handleButtonClick}
            style={{ backgroundColor: "#FF70A6", color: "#fff" }}
          >
            ช่องทางการสั่งซื้อ
          </button>
        </div>
      </div>

      <div className="container mt-5">
        <div >
          <div className="text-center p-2 mt-5 mb-4 custom-heading">
            รีวิวจากผู้ใช้งาน
          </div>
        </div>

        <Carousel>
          <Carousel.Item>
            <Row>
              <Col>
                <img
                  className="d-block w-100"
                  src={firstSlideImage}
                  alt="First slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={secondSlideImage}
                  alt="Second slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={thirdSlideImage}
                  alt="Third slide"
                />
              </Col>
            </Row>
            <Carousel.Caption>
              {/* <h3>First group of slides</h3>
              <p>Some description for the first group.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Row>
              <Col>
                <img
                  className="d-block w-100"
                  src={fourthSlideImage}
                  alt="Fourth slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={fifthSlideImage}
                  alt="Fifth slide"
                />
              </Col>
              <Col>
                <img
                  className="d-block w-100"
                  src={firstSlideImage}
                  alt="Sixth slide"
                />
              </Col>
            </Row>
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>





      <div className="box-shadow mt-5">

        <Link to="/donate">
          <div className="col-12 custom-headingDonate-container">

          </div>
          {/* <div className="text-center mt-2 mb-5">
                  <img
                    src={DonatePok}
                    alt="Donation"
                    style={{ width: "400px", height: "auto" }} 
                  />
                </div> */}
        </Link>

        {/* <NewProduct /> */}
      </div>



      <div className="row justify-content-center align-items-center mt-2 ">
        <div className="col-12 col-md-8 mt-5 mb-5 d-flex flex-column flex-md-row align-items-center text-center text-md-left">
          {/* <img
            src={logo}
            alt="Logo"
            className="img-fluid mb-3 mb-md-0 responsive-logo" 
            style={{ maxWidth: "300px" }}
          /> */}
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <ReactTypingEffect
              text={["360 healthyshop จำหน่ายผลิตภัณฑ์เพื่อสุขภาพ"]}
              speed={100}
              eraseSpeed={50}
              eraseDelay={1000}
              typingDelay={500}
              style={{ color: "#FF1493" }}
            />
            <br />
            <br />
            <p style={{ textAlign: "justify", padding: "0 20px" }}>
              360healthyshop จำหน่ายผลิตภัณฑ์เพื่อสุขภาพ
              ผลิตภัณฑ์ที่ช่วยปรับสมดุลร่างกายทางกายภาพ เคล็ดลับการมีสุขภาพที่ดี
              ความปลอดภัยต่อผู้บริโภคเป็นสิ่งสำคัญ
              เราเลือกผลิตภัณฑ์ทุกชิ้นมีการผ่านการรับรองจากองค์กรอาหารและยา
              (อย.) และได้รับรับรองมาตราฐาน GMP
              เพื่อตอบโจทย์ปัญหาได้ตรงจุดและเข้าถึงผู้บริโภคมากที่สุด
            </p>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default Home;
