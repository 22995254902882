import React, { useEffect, useState } from "react";
import { Card, Tabs, Rate, Button, Input, message, List } from "antd";
import { HeartOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useSelector, useDispatch } from "react-redux";
import { addToWishlist } from "../function/users";
import { toast } from "react-toastify";
import _ from "lodash";
import Footer from "../layouts/Footer";
import { listPromotions } from "../function/promotion";
import "./SingleProductCard.css"; // นำเข้าไฟล์ CSS ของคุณ
import { addReview, getProductReviews } from "../function/review";
import axios from "axios";

const { TextArea } = Input;

const { Meta } = Card;
const { TabPane } = Tabs;

const SingleProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ ...state }));
  const { _id, title, description, images, price, sold, quantity, category } =
    product;
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [promotionDates, setPromotionDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [promotionTitle, setPromotionTitle] = useState("");
  const [reviews, setReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");



  useEffect(() => {
    if (product?._id) {
      fetchReviews(product._id);
    }
  }, [product]);

  const fetchReviews = async (productId) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/reviews/${productId}`);
      setReviews(data); // ตั้งค่าข้อมูลรีวิวใน state
    } catch (error) {
      console.error("Error fetching reviews:", error);
      toast.error("Failed to load reviews");
    }
  };



  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchPromotions = async () => {
      try {
        const res = await listPromotions();
        const promotions = res.data;
        const productPromotion = promotions.find((promotion) =>
          promotion.products.some((p) => p._id === product._id)
        );

        if (productPromotion) {
          const discount = productPromotion.discount;
          const discounted = product.price - (product.price * discount) / 100;
          setDiscountedPrice(discounted);
          setPromotionDates({
            startDate: new Date(
              productPromotion.startDate
            ).toLocaleDateString(),
            endDate: new Date(productPromotion.endDate).toLocaleDateString(),
          });
          setPromotionTitle(productPromotion.title);
        } else {
          setPromotionTitle("");
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPromotions();
  }, [product]);


  const handleAddToCart = () => {
    let cart = [];
    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));
    }
    cart.push({
      ...product,
      count: 1,
      discountedPrice:
        discountedPrice !== null ? discountedPrice : product.price,
    });

    let unique = _.uniqWith(cart, _.isEqual);
    localStorage.setItem("cart", JSON.stringify(unique));
    dispatch({
      type: "ADD_TO_CART",
      payload: unique,
    });
    dispatch({
      type: "SET_VISIBLE",
      payload: true,
    });
  };

  const handleAddToWishList = (e) => {
    if (user) {
      addToWishlist(user.token, _id)
        .then((res) => {
          toast.success(" เพิ่มลงในรายการที่ชื่นชอบ เรียบร้อย ");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("!! กรุณาลงชื่อเข้าใช้ !!");
    }
  };

  const handleSubmitReview = async () => {
    if (!rating || !comment) {
      toast.error("Please provide both rating and comment!");
      return;
    }

    const reviewData = {
      user: user._id, // ใช้ _id จาก user ใน Redux
      product: product._id, // ใช้ _id จาก product
      rating: rating,
      comment: comment,
    };

    try {
      const res = await addReview(user.token, reviewData); // ส่ง reviewData ไปยัง API
      toast.success("Review submitted successfully!");
      setReviews([...reviews, res]);
    } catch (error) {
      toast.error("Failed to submit review");
    }
  };








  return (
    <>
      {/* <div className="container single-product-container"> */}
      <div className="container-fluid">
        <div className="container">
          <div className="row justify-content-center align-items mt-1 ">
            <div className="card-tap col-md-5">
              <Carousel autoPlay showArrows={true} infiniteLoop showThumbs={true}>
                {images &&
                  images.map((item) => (
                    <div className="carousel-img-container" key={item.public_id}>
                      {console.log(item.url)} {/* ตรวจสอบ URL ของภาพ */}
                      <img src={item.url} className="carousel-img" />
                    </div>
                  ))}
              </Carousel>
            </div>
            {/* <div className="col-1"></div> */}
            <div className="card-tap-price col-md-6 mt-3">
              {/* <h2 className="p-2 mt-0" style={{ backgroundColor: "#eb6472", color: "#fff" }}> */}
              <h5
                className="p-2 mt-0"
                style={{
                  backgroundColor: "#fff",
                  fontFamily: "Kanit, serif",
                  color: "#302c1f",
                }}
              >
                {discountedPrice !== null ? promotionTitle : title}
              </h5>
              <Card
                actions={[
                  <div className="wishlist-cart-container">
                    <a className="wishlist-action" onClick={handleAddToWishList}>
                      <HeartOutlined className="text-info" />
                      <span>Add to wishlist</span>
                    </a>
                    <a className="cart-action mt-1" onClick={handleAddToCart}>
                      <ShoppingCartOutlined className="text-danger" />
                      <span>Add to cart</span>
                    </a>
                  </div>,
                ]}
              >
                <ul className="list-group list-group-flush mt-1">
                  <li className="list-group-item">
                    ราคา
                    <span className="float-end">
                      {discountedPrice !== null &&
                        discountedPrice !== undefined ? (
                        <>
                          <span className="original-price">
                            {price.toFixed(2)}
                          </span>{" "}
                          <span className="discounted-price">
                            {discountedPrice.toFixed(2)}
                          </span>
                        </>
                      ) : price !== null && price !== undefined ? (
                        price.toFixed(2)
                      ) : (
                        "N/A"
                      )}
                    </span>
                  </li>
                  <li className="list-group-item">
                    Quantity
                    <span className="float-end">{quantity}</span>
                  </li>
                  <li className="list-group-item">
                    ขายแล้ว
                    <span className="float-end">{sold}</span>
                  </li>
                  {product.category && (
                    <li className="list-group-item">
                      หมวดหมู่
                      <span className="float-end">{category.name}</span>
                    </li>
                  )}
                  {/* {promotionDates.startDate && (
                  <li className="list-group-item">
                    Promotion Start Date
                    <span className="float-end">{promotionDates.startDate}</span>
                  </li>
                )}
                {promotionDates.endDate && (
                  <li className="list-group-item">
                    Promotion End Date
                    <span className="float-end">{promotionDates.endDate}</span>
                  </li>
                )} */}
                </ul>
              </Card>
            </div>
          </div>

          <div className="row justify-content-center align-items-center my-5">
            <div className="col-12 col-md-12">
              <div className="custom-tabs-container shadow-sm p-4 rounded">
                <Tabs
                  tabBarStyle={{ fontFamily: 'Sarabun, serif', fontWeight: 600, fontSize: '1.1rem' }}
                  type="card"
                  className="custom-tabs"
                >
                  <TabPane tab="Description" key="1">
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                      style={{
                        fontFamily: 'Sarabun, serif',
                        fontSize: '1rem',
                        lineHeight: '1.6',
                      }}
                    />
                  </TabPane>
                  <TabPane tab="More Details" key="2">
                    <p
                      style={{
                        fontFamily: 'Sarabun, serif',
                        fontSize: '1rem',
                        lineHeight: '1.6',
                      }}
                    >
                      Here is additional information about the product or anything you want to show.
                    </p>
                  </TabPane>
                  <TabPane tab="Review" key="3">
                    <p
                      style={{
                        fontFamily: 'Sarabun, serif',
                        fontSize: '1rem',
                        lineHeight: '1.6',
                      }}
                    >
                      รีวิลสินค้าจากผู้ใช้
                    </p>

                    {/* แสดงรีวิว */}
                    <div className="reviews-section">
                      <div className="reviews-header">Reviews</div>
                      {reviews.length > 0 ? (
                        <List
                          dataSource={reviews}
                          renderItem={(review) => (
                            <List.Item key={review._id}>
                              <div className="review-item mt-2">
                                <div className="review-author">{review.user?.username || "Anonymous"}</div>
                                <Rate disabled value={review.rating} className="review-rating" />
                                <div className="review-comment">{review.comment}</div>
                                <div className="review-time">
                                  {new Date(review.createdAt).toLocaleString("th-TH", {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </div>
                              </div>
                            </List.Item>
                          )}
                        />
                      ) : (
                        <div className="no-reviews">No reviews yet. Be the first to review this product!</div>
                      )}
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
          <div>


            {/* ฟอร์มสำหรับเพิ่มรีวิว */}
            <div className="add-review-section">
              <h4>Add a Review</h4>
              <Rate value={rating} onChange={(value) => setRating(value)} />
              <TextArea
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Write your review here"
              />
              <Button onClick={handleSubmitReview} type="primary" className="mt-2">
                Submit Review
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SingleProductCard;
