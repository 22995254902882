import React from 'react'

const ShopEmoloyee = () => {
  return (
    <div className='mt-5'>
      <h2>ร้านค้าสวัlดิการ</h2>
      <p>สินค้าที่1</p>
      <p>สินค้าที่2</p>
      <p>สินค้าที่3</p>
    </div>
  )
}

export default ShopEmoloyee
